export const tag = (action, {category, label, value} = {}) =>
  window.gtag && window.gtag('event', action, {
    event_category : category,
    event_label : label,
    value
  });

const createWrapper = (tagFunction) => (action, meta) => (callback) => (...args) => {
  tagFunction(action, meta);
  return callback(...args);
};


export const navigationTag = (action, meta = {}) => tag(action, {...meta, category : 'navigation'});
export const navigationTagWrapper = createWrapper(navigationTag);

export const behaviourTag = (action, meta = {}) => tag(action, {...meta, category : 'behaviour'});
export const behaviourTagWrapper = createWrapper(behaviourTag);
