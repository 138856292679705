import React from "react"
// import useDimensions from 'react-use-dimensions';
import styled from "styled-components"

import backgroundLargeDot from '../images/background-large-dot.png';

// const IMAGE_WIDTH  = 35;
// const IMAGE_HEIGHT = 30;

// const getImageDimensions = (imageUrl) => {
//   const image = new Image();
//   image.src = imageUrl;
//
//   return {
//     width : image.width,
//     height : image.height
//   };
// };

const Background = styled.div`
  background-repeat : repeat-xy;
  background-size : 4rem 4rem;

  @keyframes background-movements {
    0% {
      transform : translate(0rem, 0rem);
    }

    20% {
      transform : translate(4rem, 2rem);
    }

    40% {
      transform : translate(-4rem, 4rem);
    }

    60% {
      transform : translate(0rem, -2rem);
    }

    80% {
      transform : translate(2rem, 4rem);
    }

    100% {
      transform : translate(0rem, 0rem);
    }
  }

  animation : background-movements 60s ease-in-out infinite both;
`;

const DotBackground = ({animate = 0, style = {}, ...props}) => {
  // const [ref, { width, height }] = useDimensions();
  // const [positions, setPositions] = useState([]);

  // const cellDimensions = getImageDimensions(backgroundLargeDot);
  // const xCells = Math.ceil(width/cellDimensions.width);
  // const yCells = Math.ceil(height/cellDimensions.height);

  // if (animate) {
  //   if (!positions.length) {
  //     const
  //   }
  // }

  return (
    <Background
      /*ref={ref}*/
      style={{
        backgroundImage : `url('${backgroundLargeDot}')`,
        ...style
      }}
      {...props}
    />
  )
}

export default DotBackground;
