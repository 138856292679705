import React from 'react';
import styled from "styled-components"

import Link, { AnalyticsExternalLink } from '../components/analytics/analyticslink';

import content from '../data/static-content.js';

const FooterContainer = styled.footer`

`;

const FooterLinkSections = styled.div`
  background-color : #535CF4;
  padding : 7.2rem;
  color : white;
  display : flex;
  flex-direction : row;
  justify-content : space-between;

  @media screen and (max-width: 425px) {
    flex-direction : column;
    justify-content : flex-start;
  }

`;

const FooterColumn = styled.div`
  padding : 0 3rem;
  display : flex;
  flex-direction : column;
  justify-content : space-between;

  @media screen and (max-width: 425px) {
    align-items : center;
  }

`

const FatLink = styled(Link)`
  color : white;
  font-weight : 800;
  font-size : 1.5rem;
  text-decoration : none;
  margin-top : 3.2rem;
`;

const Lightlink = styled(Link)`
  color : white;
  font-size : 1.5rem;
  text-decoration : none;
  margin-top : 3.2rem;
`;

const FooterLeft = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : flex-start;

  @media screen and (max-width: 425px) {
    flex-direction : column;
    justify-content : flex-start;
  }

`;

const Logo = styled.img`
  margin-top : 3.2rem;
  width : 11.7rem;
  height : 3.1rem;

  @media screen and (max-width: 425px) {
    display : none
  }
`;

const SocialContainer = styled.div`
  @media screen and (max-width: 425px) {
    text-align : center;
  }
`;

const SocialLabel = styled.div`
  color : white;
  font-size : 1.5rem;
  font-weight : 800;
  margin-bottom : 2rem;
  margin-top : 6rem;
`;

const SocialLogo = styled.img`
  width : 2.7rem;
  height : 2.7rem;
  margin-right : 1.8rem;

  @media screen and (max-width: 425px) {
    margin-left : 0.9rem;
    margin-right : 0.9rem;
  }
`;

const CopyrightSection = styled.div`
  height : 8.9rem;
  background-color : white;
  color : black;
  font-size : 1.4rem;
  display : flex;
  align-items : center;
  justify-content : center;
`

const Footer = (props) => (
  <FooterContainer>
    <FooterLinkSections>
      <FooterLeft>
        <FooterColumn>
          <FatLink action="footer->home" to="/">{content.menu.home}</FatLink>
          {/* <FatLink action="footer->about" to="/about">{content.menu.about}</FatLink> */}
          <FatLink action="footer->expertise" to="/expertise">{content.menu.method}</FatLink>
          <FatLink action="footer->references" to="/references">{content.menu.references}</FatLink>
        </FooterColumn>
        <FooterColumn>
          <Lightlink action="footer->blog" to="/blog">{content.menu.blog}</Lightlink>
          <Lightlink action="footer->contact" to="/contact">{content.menu.contact}</Lightlink>
          <Lightlink action="footer->mentions-legales" to="/mentions-legales">{content.menu.legal}</Lightlink>
          <Lightlink action="footer->politique-de-confidentialite" to="/politique-de-confidentialite">{content.menu.privacy}</Lightlink>
        </FooterColumn>
      </FooterLeft>
      <FooterColumn>
        <Logo src={require('../images/logo-white.svg')} />
        <SocialContainer>
          <SocialLabel>{content.footer.socialLabel}</SocialLabel>
          <AnalyticsExternalLink action="footer->linkedin" href={content.footer.linkedinUrl} target="_blank" rel="noopener noreferrer">
            <SocialLogo src={require('../images/linkedin.svg')}/>
          </AnalyticsExternalLink>
          <AnalyticsExternalLink action="footer->twitter"  href={content.footer.twitterUrl} target="_blank" rel="noopener noreferrer">
            <SocialLogo src={require('../images/twitter.svg')}/>
          </AnalyticsExternalLink>
          <AnalyticsExternalLink action="footer->insta"  href={content.footer.instagramUrl} target="_blank" rel="noopener noreferrer">
            <SocialLogo src={require('../images/insta.svg')}/>
          </AnalyticsExternalLink>
          <AnalyticsExternalLink action="footer->facebook"  href={content.footer.facebookUrl} target="_blank" rel="noopener noreferrer">
            <SocialLogo src={require('../images/facebook.svg')}/>
          </AnalyticsExternalLink>
        </SocialContainer>
      </FooterColumn>
    </FooterLinkSections>
    <CopyrightSection>
      © 2019-{new Date().getFullYear()} - Tous droits réservés - Appunto
    </CopyrightSection>
  </FooterContainer>
);

export default Footer;
