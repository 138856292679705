import React from "react"
import { Link } from "gatsby"

import { navigationTagWrapper } from '../../shared/analytics';

const nop = () => {};

const AnalyticsLink = ({action = 'navigation', onClick = nop, onKeyPress = nop, ...props}) =>
  <Link {...props}
    onClick={navigationTagWrapper(action)(onClick)}
    onKeyPress={navigationTagWrapper(action)(onClick)}
  />;


const AnalyticsExternalLink = ({action = 'navigation', onClick = nop, onKeyPress = nop, ...props}) =>
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a {...props}
    onClick={navigationTagWrapper(action)(onClick)}
    onKeyPress={navigationTagWrapper(action)(onClick)}
    tabIndex="0"
    role="link"
  />;

export default AnalyticsLink;
export {
  AnalyticsExternalLink
};
