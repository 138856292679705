import React from "react";
import { Link } from "gatsby"
import styled from "styled-components"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { navigationTag } from '../shared/analytics';

import content from '../data/static-content.js';
import { colors } from '../shared/styles';

const Container = styled.div`
  position : fixed;
  bottom : 0px;
  width : 100%;
  background-color : ${colors.lightGrey};
  padding-left : 8rem;
  font-size : 1.3rem;
  transition : transform 0.3s ease-in-out;
  display : flex;
  flex-direction : row;
  align-items: center;

  @media screen and (max-width : 425px) {
    padding-top : 6rem;
    padding-left : 4rem;
    top : 0px;
    bottom : auto;
  }

  &.hidden {
    transform : translateY(100%);

    @media screen and (max-width : 425px) {
      transform : translateY(-100%);
    }
  }

  & a, & a:active, & a:hover {
    color : black;
  }
`;

const Paragraph = styled.p`
  margin-top : 4rem;
  margin-bottom : 4rem;
  text-align : justify;
`;

const CloseCross = styled.span`
  font-size : 3rem;
  font-weight : 800;
  padding : 3rem 8rem;
  cursor : pointer;

  @media screen and (max-width : 425px) {
    padding : 3rem 4rem;
  }
`

const tagPrivacy = () => navigationTag('gdprbanner->privacy');

const GDPRBannner = ({visible, onConsent}) => {
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const collapsed = currPos.y <= -400

      if(collapsed && visible) {
        onConsent();
      }
    },
    [visible]
  );

  return (
    <Container className={!visible ? 'hidden' : ''}>
      <Paragraph>{content.gdpr.text} <Link onClick={tagPrivacy} onKeyDown={tagPrivacy} to="/politique-de-confidentialite">{content.gdpr.link}</Link></Paragraph>
      <CloseCross onKeyPress={onConsent} onClick={onConsent}>✕</CloseCross>
    </Container>
  );
}

export default GDPRBannner;
