const ONE_YEAR = 365*24*3600000;

export const getGdprConsentCookie = () =>
  typeof document !== `undefined` && document.cookie.split('; ').map(keyValue => keyValue.split('=')).find(([key, value]) => key === 'gdpr-consent');

export const hasGdprConsentCookie = () => !!getGdprConsentCookie();

export const hasConsent = () =>
  (getGdprConsentCookie() || [])[1] === 'true';

export const setConsent = (consent) => {
  if(typeof document !== `undefined`) {
    document.cookie = `gdpr-consent=${consent ? 'true' : 'false'}; expires=${new Date(Date.now()+ONE_YEAR).toUTCString()}; path=/`;
  }
}
