import React from "react"
import styled from 'styled-components';

import Link from '../components/analytics/analyticslink';
import DotBackground from './dotbackground';
import CloseButton from './closebutton';

import logoO from '../images/logo-o.svg';

import content from '../data/static-content.js';

const Close = styled(CloseButton)`
  position : absolute;
  top : 0px;
  right : 0px;
  margin : 3rem 4rem;
`;


const Menu = ({children, onClick, className = '', style, visible, ...props}) =>
  <div
    className={`menu ${visible ? 'visible' : ''} ${className}`}
    style={style}
  >
    <div className="background" />
    <DotBackground
      style={{
        position : 'absolute',
        top      : 0,
        left     : 0,
        width    : '100%',
        height   : '100%'
      }}
    />
    <div className="logo">
      <img src={logoO} className="logo-o" alt=""/>
      <div className="logo-red-dot"/>
      <div className="logo-blue-dot"/>
    </div>
    <div className="content">
      <div className="item"><Link action="menu->home" to="/">{content.menu.home}</Link></div>
      {/* <div className="item"><Link action="menu->about" to="/about">{content.menu.about}</Link></div> */}
      <div className="item"><Link action="menu->exertise" to="/expertise">{content.menu.method}</Link></div>
      <div className="item"><Link action="menu->references" to="/references">{content.menu.references}</Link></div>
      <div className="item"><Link action="menu->blog" to="/blog">{content.menu.blog}</Link></div>
      <div className="item"><Link action="menu->contact" to="/contact">{content.menu.contact}</Link></div>
      <div className="item-selector is3"></div>
      <div className="item-selector is2"></div>
      <div className="item-selector is1"></div>
    </div>
    <Close tabIndex="0" onKeyPress={onClick} onClick={onClick} role="button"/>
  </div>;

export default Menu;
