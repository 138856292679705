export default {
  menu : {
    home : 'Appunto',
    about : 'Qui sommes nous ?',
    method : 'Expertise',
    references : 'Références',
    blog : 'Blog',
    contact : 'Nous contacter',
    legal : 'Mentions légales',
    privacy : 'Politique de confidentialité'
  },
  footer : {
    socialLabel  : 'Rejoignez-nous !',
    linkedinUrl  : 'https://www.linkedin.com/company/appunto',
    twitterUrl   : 'https://twitter.com/AppuntoSAS',
    instagramUrl : 'https://www.instagram.com/appuntosas/',
    facebookUrl  : 'https://www.facebook.com/appuntosas'
  },
  home : {
    main : {
      title       : 'Créateur',
      titleLine2  : 'web et mobile',
      description : [
        'Appunto est l’agence de développement partenaire de startups et grandes marques.',
        `Notre rôle est d’être l’allié technique de toutes sociétés ayant besoin d’applications mobiles et logiciels web.`,
        `Nous proposons un accompagnement sur mesure pour créer le produit le plus fidèle à votre vision.`,
        // 'Notre vision d\'ensemble et notre approche globale nous permet d\'amener la technique là où sont vos vos besoins et pas l\'inverse.',
      ],
      button      : 'Découvrir nos clients'
    },
    activitiesTitle : 'Notre offre',
    activities : [
      {
        title : 'CTO externalisé',
        description : 'Votre garantie de succès sur le long terme.'
      },
      {
        title : 'Applications mobiles',
        description : 'Vos applications mobiles clefs en mains.'
      },
      {
        title : 'Logiciels web',
        description : 'Votre future solution SaaS prête à la vente.'
      }
    ],
    method : {
      title : 'Notre Expertise',
      button : 'En savoir plus'
    },
    references : {
      title : 'Références',
      button : 'Voire toutes nos références'
    },
    contacts : {
      title : 'Contactez nous !',
      button : 'Envoyer'
    }
  },
  method : {
    title : 'Expertise',
    tiles : [
      {
        title   : 'Co-construction',
        hash    : 'audit',
        content : `
Avec des techniques de co-construction, nous
faisons travailler ensemble clients, utilisateurs
finaux et équipe technique pour vous aider dès le
départ à définir le produit idéal.
        `,
        images : [

        ]
      },
      {
        title   : 'Conseil',
        hash    : 'conseil',
        content : `
Notre offre de CTO externalisé permet à nos clients d'être accompagnés dans leurs choix technologies fondamentaux et dans le suivi de leurs projets.
        `,
        images : [

        ]
      },
      {
        title   : 'Design',
        hash    : 'design',
        content : `
Nous travaillons en partenariat avec des UX et UI designers
expérimentés qui savent créer l’image parfaite
pour votre produit.
        `,
        images : [

        ]
      },
//       {
//         title   : 'Déploiement',
//         hash    : 'deploiement',
//         content : `
// Nous gerons le deployement de vos applications dans les App stores and dans le cloud de votre choix.
//         `,
//         images : [
//           require('../images/logo-ovh.png'),
//           require('../images/logo-aws.jpg')
//         ]
//       },
      {
        title   : 'Développement',
        hash    : 'dev',
        content : `
Experts React, ReactNative et Node, nous maintenons une veille technologique active pour proposer constamment à nos clients les meilleurs technologies et méthodologies du marché.
        `,
        images : [

        ]
      },
      {
        title   : 'Opérations',
        hash    : 'ops',
        content : `
Le développement ne sera jamais complet si on ne prend pas en compte depuis le départ les problématiques liés aux opérations. C'est pour cette raison que nous mettons en œuvre des processus et des méthodologies DevOps sur tous nos projets.
        `,
        images : [
          // require('../images/logo-ovh.png'),
          // require('../images/logo-aws.jpg')
        ]
      },
      {
        title   : 'Monitoring',
        hash    : 'monitoring',
        content : `
Un système en production sans outils de monitoring est comme une voiture de course conduite par un aveugle !
Nos outils de monitoring permettent de suivre l’état de votre système en temps réel afin de réagir rapidement en cas de problème.
        `,
        images : [

        ]
      },
    ]
  },
  references : {
    title : 'Références',
    description : 'Chez Appunto nous faisons des choses super cools, nous aimons nos projets et nos clients, alors si le prochain c’était vous?',
  },
  about : {
    title : 'Qui sommes-nous ?',
    partnersTitle : 'Nos partenaires'
  },
  messageSent : {
    title : 'Message envoyé',
    description : [
      'Merci pour votre message, il a bien été pris en compte.',
      'Nous allons vous repondre dans les plus brefs delais.'
    ],
    backButton : 'Retour au site'
  },
  mentions : {
    title : 'Mentions légales',
    titleEditeur : 'Editeur du site',
    editeur : {
      'Raison sociale' : 'Appunto SASU a capital variable',
      'SIREN'	         : '834 268 310',
      'Capital social' : '3000€',
      'Adresse'        : '9, rue du Général Leclerc, 92130 Issy-les-Moulineaux',
      'Email'          : 'contact@appunto.io'
    },
    titleDirPub : 'Directeur de publication',
    dirPub : {
      'Contact' : 'Mattia Longhin',
      'Email'   : 'contact@appunto.io'
    },
    titleHosting : 'Hébergement',
    hosting : {
      'Hébergeur' : 'Netlify',
      'Adresse'   : `Netlify
      2325 3rd St #215
      San Francisco
      CA 94107
      USA`
    }
  },
  privacy : {
    title : 'Politique de confidentialité',
    content : [
      {
        title : 'Introduction',
        content : [
          `
            Dans le cadre de son activité, la société Appunto, dont le siège social est situé au 9, rue du Général Leclerc, 92130 Issy-les-Moulineaux, est amenée à collecter et à traiter des informations dont certaines sont qualifiées de "données personnelles". Appunto attache une grande importance au respect de la vie privée, et n’utilise que des donnes de manière responsable et confidentielle et dans une finalité précise.
          `
        ]
      },
      {
        title : 'Données personnelles',
        content : [
          `
            Sur le site web appunto.io, il y a 2 types de données susceptibles d’être recueillies : les données transmises directement et les données collectées automatiquement.
          `,
          `
            Les données transmises directement sont celles que vous nous transmettez directement via le formulaire de contact ou bien par contact direct par email. Est obligatoire dans le formulaire de contact le champ « email ».
          `,
          `
            Lors de vos visites, une fois votre consentement donné, nous pouvons recueillir des informations de type « web analytics » relatives à votre navigation, la durée de votre consultation, votre adresse IP, votre type et version de navigateur. La technologie utilisée est le cookie.
          `
        ]
      },
      {
        title : 'Utilisation des données',
        content : [
          `
            Les données que vous nous transmettez directement sont utilisées dans le but de vous re-contacter et/ou dans le cadre de la demande que vous nous faites. Les données « web analytics » sont collectées de forme anonyme (en enregistrant des adresses IP anonymes) par Google Analytics, et nous permettent de mesurer l'audience de notre site web, les consultations et les éventuelles erreurs afin d’améliorer constamment l’expérience des utilisateurs. Ces données sont utilisées par Appunto, responsable du traitement des données, et ne seront jamais cédées à un tiers ni utilisées à d’autres fins que celles détaillées ci-dessus.
          `
        ]
      },
      {
        title : 'Base légale',
        content : [
          `
            Les données personnelles ne sont collectées qu’après consentement obligatoire de l’utilisateur. Ce consentement est valablement recueilli, libre, clair et sans équivoque.
          `
        ]
      },
      {
        title : 'Cookies',
        content : [
          `
            Voici la liste des cookies utilisées et leur objectif : Cookies Google Analytics.
          `
        ]
      },
      {
        title : 'Vos droits concernant les données personnelles',
        content : [
          `
            Vous avez le droit de consultation, demande de modification ou d’effacement sur l’ensemble de vos données personnelles. Vous pouvez également retirer votre consentement au traitement de vos données.
          `
        ]
      },
      {
        title : 'Contact délégué à la protection des données',
        content : [
          `
            Mattia Longhin <contact@appunto.io>
          `
        ]
      },
    ],
    consentTitle : 'Consentement',
    consentText  : "J'accepte l'utilisation des cookies dans le cadre légale décrit ci-dessus : ",
    consentGiven : 'accepté',
    consentRejected : 'refusé',
    giveConsent     : 'Accepter',
    rejectConsent   : 'Refuser'
  },
  blog : {
    title : 'Notre blog',
    subtitle : 'Tous nos articles'
  },
  blogWait : {
    title : 'Pas encore...',
    description : 'Soyez patients, notre blog est en cours de preparation.'
  },
  error404 : {
    title : 'Erreur 404 😢',
    description : 'Oups, la page que vous cherchez n\'existe pas.'
  },
  gdpr : {
    text : "Nous utilisons des cookies pour nous permettre de mieux comprendre comment le site est utilisé. En continuant à utiliser ce site, vous acceptez cette politique.",
    link : "Pour en savoir plus et parametrer les cookies"
  },
  blogArticle : {
    footer : "Une idée, un projet ?",
    footerButton : 'Discutons-en !'
  }
};
