import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";

import GDPRBannner from './gdprbanner';

import { hasGdprConsentCookie, hasConsent, setConsent } from '../shared/cookies';

import config from '../data/config';

function Analytics() {
  const [cookieStored, setCookieStored] = useState(true);
  const [consentAquired, setConsentAquired] = useState(false);

  useEffect(() => {
    setCookieStored(hasGdprConsentCookie());
    setConsentAquired(hasConsent());
  }, [cookieStored, consentAquired]);


  const onConsent = () => {
    setConsentAquired(true);
    setConsent(true);
    setCookieStored(true);
  };

  return (
    <>
      <Helmet>
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${config.GA_MEASUREMENT_ID}');
        `}
        </script>
        {
          consentAquired ?
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_MEASUREMENT_ID}`}></script>
            :
            null
        }
        {
          consentAquired ?
            <script>
              {`
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:1802920,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
            </script>
            :
            null
        }
      </Helmet>
      <GDPRBannner visible={!cookieStored} onConsent={onConsent} />
    </>
  )
};

export default Analytics;
