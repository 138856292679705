import React, { useState } from "react"
import styled from "styled-components"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Link from '../components/analytics/analyticslink';
import Logo from './logo';
import BurgerMenuButton from './burgermenubutton.js';
import ContactButton from '../components/contactButton';
import { dimensions } from '../shared/styles';

const HeaderContainer = styled.header`
  background-color  : white;
  padding-left      : 4rem;
  padding-right     : 4rem;
  position          : fixed;
  top               : 0px;
  width             : 100%;
  height            : ${dimensions.headerHeight};
  display           : flex;
  flex-direction    : row;
  justify-content   : space-between;
  align-items       : center;
  z-index           : 1;
  transition        : all 300ms ease;

  &.reduced, &.home-animated {
    height : 6rem;
    paddingLeft : 2rem;
    paddingRight : 2rem;
  }

  &.hide {
    height : 6rem;
    paddingLeft : 2rem;
    paddingRight : 2rem;
    transform : translateY(0rem);
  }

  &.hidden {
    height : 6rem;
    paddingLeft : 2rem;
    paddingRight : 2rem;
    transform : translateY(-6rem);
  }

  @media screen and (max-width: 425px) {
    &.home-normal {
      height : 6rem;
      paddingLeft : 2rem;
      paddingRight : 2rem;
      transform : translateY(-6rem);
    }

    &.home-animated {
      height : 6rem;
      paddingLeft : 2rem;
      paddingRight : 2rem;
      transform : translateY(0rem);
    }
  }
`;

const Header = ({ onBurgerClick, animation = 'reduce' }) => {
  const normalStateClass = ({
    'reduce' : 'reduce',
    'hide'   : 'hidden',
    'home'   : 'home-normal'
  })[animation] || 'reduce';

  const animatedStateClass = ({
    'reduce' : 'reduced',
    'hide'   : 'hide',
    'home'   : 'home-animated'
  })[animation] || 'reduced';

  const [headerClass, setHeaderClass] = useState(normalStateClass);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const top = currPos.y > -30

      const shouldBeClass = top ? normalStateClass : animatedStateClass;

      if (shouldBeClass === headerClass) return

      setHeaderClass(shouldBeClass)
    },
    [headerClass]
  );

  return (
    <HeaderContainer className={headerClass}>
      <Link action="header->home" to="/"><Logo /></Link>
      <div>
        <Link action="header->contact" to="/contact"><ContactButton>Contactez-nous</ContactButton></Link>
        <BurgerMenuButton onClick={onBurgerClick}/>
      </div>
    </HeaderContainer>
  );
};
export default Header
