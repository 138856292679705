export const dimensions = {
  headerHeight : '10rem'
};


export const colors = {
  blue      : '#525DF4',
  darkBlue  : '#252F3A',
  pink      : '#F75252',
  lightGrey : '#E7EAEE',
  blueGrey  : '#91A5B9'
};
