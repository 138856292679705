import React from "react"

const ContactButton = ({children, onClick, className = '', style, ...props}) =>
  <div
    className={`contact-button-container ${className}`}
    style={style}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex="0"
  >
    <div className='contact-button-content'>
      {children}
    </div>
  </div>;

export default ContactButton;