import React, {useState} from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from './footer';
import Menu from './menu';
import Analytics from './analytics';

import { behaviourTagWrapper } from '../shared/analytics';

import "./layout.css"
import "./components.css"

const Layout = ({ children, headerProps = {} }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const onMenuOpen = behaviourTagWrapper('menu-open')(() => setMenuVisible(true));
  const onMenuClose = behaviourTagWrapper('menu-close')(() => setMenuVisible(false));

  return (
    <>
      <Header onBurgerClick={onMenuOpen} {...headerProps}/>
      {children}
      <Footer />
      <Menu onClick={onMenuClose} visible={menuVisible} />
      <Analytics />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
