import React from 'react';
import styled from 'styled-components';

import closeImage from '../images/close-cross.svg';

const ButtonImage = styled.img`
  width : 2.5rem;
  height : 2.5rem;
  cursor : pointer;
`;

const CloseButton = (props) => (
  <ButtonImage src={closeImage} {...props}/>
);

export default CloseButton;
