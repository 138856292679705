import React from "react"
import bugerImage from '../images/burger-menu.svg'

const BurgerMenuButton = ({onClick, className = '', style, ...props}) =>
  <div
    className={`burger-button ${className}`}
    style={style}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex="0"
  >
    <img src={bugerImage} style={{width : '2.5rem', height : '1.8rem'}} alt=""/>
  </div>;

export default BurgerMenuButton;
